.testimonials {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: #000b17;
    padding: 50px 0;
}

.testimonial-container {
    display: flex;
    flex-direction: row;
}

.text-container2 {
    display: flex;
    flex-direction: column;
    text-align: start;
    padding: 10em;
    align-content: center;
    justify-content: center;
    align-items: stretch;
}

.text-container2 p {
    color: white;
    font-family: 'Montserrat';
    font-size: 2rem;
    font-weight: bold;
}

.testimonial {
    width: 45%;
    padding: 20px;
    background-color: #f5f5f5;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-bottom: 40px;
}

.testimonial h3 {
    text-align: center;
    margin-bottom: 15px;
}

.testimonial p {
    font-size: 0.9rem;
    text-align: justify;
}

.suscription-button2 {
    z-index: 1;
    margin-top: 15px;
    z-index: 1;
    padding: 10px 30px;
    font-family: 'Montserrat';
    font-size: 20px;
    font-weight: 700;
    line-height: 1.5em;
    color: white;
    border: none;
    outline: none;
    border-radius: 15px;
    background: #2d91d4;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
    position: relative;
    overflow: hidden;
}

.suscription-button ::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.5);
    width: 100%;
    height: 100%;
    background: rgba(45, 145, 212, 0.2);
    border-radius: 30px;
    z-index: -1;
    filter: blur(20px);
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
}

.suscription-button :hover::after {
    opacity: 1;
    /* Cambia la opacidad en hover para mostrar el efecto */
}

.suscription-button :hover {
    background: rgba(45, 145, 212, 0.8);
}


.suscription-button :focus,
.suscription-button :active {
    outline: none;
}

.formulario {
    display: flex;
    flex-direction: column;
    color: white;
    height: 100%;
    min-height: 100%;
    border-radius: 5px;
    padding: 40px;
    background-image: linear-gradient(to right, #2e4b57, #0a0e1e);
}

.container-input-email {
    margin-bottom: 20px;
}

.container-input-name {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    gap: 20px;
}

.input-email {
    flex: 1;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    cursor: text;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    position: relative;
    border-radius: 4px;
    color: rgb(43, 39, 40);
    border-radius: 0.5rem;
    background-color: white;
    padding-inline: 15px;
    border-width: 0px;
    padding-block: 10px;
}

.select-plan {
    flex: 1;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    cursor: text;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    position: relative;
    border-radius: 4px;
    color: rgb(43, 39, 40);
    border-radius: 0.5rem;
    background-color: white;
    padding-inline: 15px;
    border-width: 0px;
    padding-block: 10px;
}

.title-container {
    margin-bottom: 40px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    /* Asegúrate de que sea más alto que otros elementos de la página */

}

.modal-content {
    color: white;
    font-size: 1.4em;
    font-family: 'Montserrat';
    font-weight: 600;
    border-radius: 5px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    background-image: linear-gradient(to right, #2e4b57, #0a0e1e);
}




.cancel-button {
    z-index: 1;
    text-decoration: none;
    padding: 15px 40px;
    margin-top: 20px;
    font-size: 1em;
    font-family: 'Montserrat';
    font-weight: 600;
    color: white;
    border: none;
    outline: none;
    border-radius: 15px;
    background: #2d91d4;
    box-shadow:
        0 0 10px rgba(45, 145, 212, 0.5),
        0 0 10px rgba(45, 145, 212, 0.5),
        0 0 10px rgba(45, 145, 212, 0.5),
        0 0 10px rgba(45, 145, 212, 0.5),
        0 0 10px rgba(45, 145, 212, 0.5),
        0 0 10px rgba(45, 145, 212, 0.5);
    cursor: pointer;
    transition: all 0.25s ease-in-out;
    position: relative;
    overflow: hidden;
}

.cancel-button ::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.5);
    /* Escala el pseudo-elemento */
    width: 100%;
    height: 100%;
    background: rgba(45, 145, 212, 0.2);
    border-radius: 30px;
    /* Igual que el botón para mantener la forma */
    z-index: -1;
    filter: blur(20px);
    /* Difumina el pseudo-elemento para el efecto de neón */
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
    /* Inicia con una opacidad de 0 */
}

.cancel-button :hover::after {
    opacity: 1;
    /* Cambia la opacidad en hover para mostrar el efecto */
}

.cancel-button :hover {
    background: rgba(45, 145, 212, 0.8);
    /* Aumenta la opacidad del fondo en hover */
    box-shadow:
        0 0 15px rgba(45, 145, 212, 0.5),
        0 0 25px rgba(45, 145, 212, 0.5),
        0 0 35px rgba(45, 145, 212, 0.5),
        0 0 45px rgba(45, 145, 212, 0.5),
        0 0 55px rgba(45, 145, 212, 0.5),
        0 0 65px rgba(45, 145, 212, 0.5);
}

.cancel-button :focus,
.cancel-button :active {
    outline: none;
    /* Elimina el borde cuando el botón está enfocado o activo */
    box-shadow:
        0 0 5px rgba(45, 145, 212, 0.5),
        0 0 15px rgba(45, 145, 212, 0.5),
        0 0 20px rgba(45, 145, 212, 0.5),
        0 0 25px rgba(45, 145, 212, 0.5),
        0 0 35px rgba(45, 145, 212, 0.5);
}

.split-container2 {
    display: flex;
    align-items: center;
    margin-top: 2em;
}

.left-content2,
.right-content2 {
    z-index: 1;
    flex: 1 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

@media (max-width: 768px) {
    .split-container2 {
        flex-direction: column;
        margin-top: 0;
    }

    .left-content2 {
        align-items: center;
        text-align: center;
        padding: 2%;
        margin-bottom: 3em;
    }

    .modal-content {
        padding: 50px 20px;
    }

    .text-container2 {
        padding: 0 1rem;
    }

    .text-container2>p {
        font-size: 1.2rem;
    }

    .testimonials {
        padding: unset;
        padding-bottom: 2rem;
    }
}