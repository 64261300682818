.plan {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 20px;
    margin: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    background-image: linear-gradient(to right, #2e4b57, #0a0e1e);
}

.plan-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    font-family: 'Montserrat';
    color: white;
    font-size: 2em;
}

.plan-header span {
    font-weight: bold;
}

.plan ul {
    list-style-type: none;
    padding: 0;
}

.plan ul li {
    margin-bottom: 1em;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    color: white;
    font-size: 1em;
}

.check-icon {
    font-size: 1.3em;
}

.cross-icon {
    color: #007bff;
    font-size: 1.3em;
}

.plan-prices {
    display: flex;
    gap: 8px;
    align-items: center;
}

.original-price {
    text-decoration: line-through;
    color: #999;
    font-size: 1rem;
}

.offer-price {
    color: white;
    font-weight: bold;
    font-size: 1.5rem;
}

@media (max-width: 768px) {
    .plan {
        padding: 2em;
        margin: 1em;
        background: #1c2d3b;
    }

    .plan-header {
        font-size: 1.5em;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
    }

    .plan ul {
        margin-bottom: 3em;
        margin-top: 3em;
    }

    .plan ul li {
        margin-bottom: 1em;
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        color: white;
        font-size: 1em;
    }

    .plan button {
        margin-top: 2em;
    }
}