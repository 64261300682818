.title-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10rem;
    color: white;
    font-family: 'Montserrat', sans-serif;
}

@media (max-width: 768px) {
    .title-container-large {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;
        font-family: 'Montserrat', sans-serif;
        padding: 1rem;
    }
}