.whatsapp-floating-button {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 20px;
    right: 20px;
    background-color: #25D366;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    z-index: 100;
}

.whatsapp-floating-button img {
    width: 125%;
    /* Ajusta el tamaño del ícono dentro del botón */
    height: auto;
}