.Navbar {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 10px 50px;
    background-color: transparent;
    /* Fondo transparente para ver la imagen detrás */
    transition: background-color 0.5s ease;
    /* Transición suave para el color de fondo */
    position: fixed;
    /* Fija la barra de navegación en la parte superior */
    width: 100%;
    /* Asegúrate de que la barra de navegación se extienda a lo ancho */
    top: 0;
    /* Alinea la barra de navegación en la parte superior */
    z-index: 10;
    /* Asegúrate de que la barra de navegación se muestre encima del resto de los elementos */
    margin-right: 10%;
    gap: 80px;
}

.logo {
    z-index: 10;
}

.logo img {
    height: 75px;
    /* Ajusta esto según el tamaño deseado para tu logo */
}

.navbar-links {
    display: flex;
    margin-right: 10%;
    align-items: center;
}

.nav-button {
    margin-left: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1.5em;
    font-family: 'Montserrat Light';
    font-weight: bold;
    background-color: transparent;
    text-decoration: none;
}

.nav-button:focus {
    text-decoration: underline;
}


.nav-button:hover {
    text-decoration: underline;
}

.nav-button.active {
    text-decoration: underline;
}


/* Estilos para dispositivos móviles */
@media (max-width: 768px) {

    .Navbar {
        display: flex;
        justify-content: space-between;
        padding: 0;
        background-color: transparent;
        transition: background-color 0.5s ease;
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 10;
        margin-right: 0;
        gap: 0;

    }

    .hamburger-menu {
        display: flex;
        font-size: 3em;
        background: transparent;
        color: white;
        border: 0;
        z-index: 10;
        padding: 0;
    }

    /* Estilos cuando el menú esté cerrado */
    .navbar-links {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.9);
        /* Transparencia para ver el fondo detrás */
        transition: transform 0.3s ease;
        z-index: 9;
        gap: 10%;
        /* Asegura que esté debajo del botón del menú */
    }

    /* Estilos para los enlaces del menú */
    .navbar-links a {
        color: white;
        text-align: center;
        text-decoration: none;
        width: 100%;
        display: block;
        transition: background-color 0.3s ease;
    }

    .navbar-links a:hover {
        background-color: #333;
        /* Efecto hover */
    }

    /* Muestra el menú cuando .open esté activo */
    .navbar-links.open {
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        width: 100%;
        padding-top: 40%;
        z-index: 1;
        gap: 20%
    }

    .navbar-links.open a{
        margin-left: 0;
        flex: 0;
    }
    
    .nav-button {
        padding: 0;
    }
}