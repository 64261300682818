.services-section {
    display: flex;
    flex-direction: column;
    padding: 0;
    background-color: #000b17;
    gap: 4em;
    margin-top: 10em;
    padding: 0 60px;
}

.section-subtitle {
    font-size: 55px;
    text-transform: uppercase;
    font-family: 'Montserrat Black';
    color: white;
    margin-bottom: 10px;
}

.service-1 {
    grid-column-start: 1;
}

.service-2 {
    grid-column-start: 2;
}

.service-3 {
    grid-column-start: 3;
}

.text-container {
    display: flex;
    flex-direction: column;
    text-align: start;
    padding: 2rem 10rem;
    width: 40%;
    align-content: center;
    justify-content: center;
    align-items: stretch;
}

.text-container span {
    color: white;
    font-family: 'Montserrat';
    font-size: 2rem;
    font-weight: bold;
}

.title {
    font-size: 59px;
    position: relative;
    /* Posición relativa para el pseudo-elemento */
    white-space: nowrap;

    /* Asegura que el título se mantenga en una sola línea */
    padding: 0;
    /* Asegúrate de que no haya padding que afecte la posición */
    margin: 0;
    /* Asegúrate de que no haya margen que afecte la posición */
}

.title::after {
    content: '';
    position: absolute;
    left: -100%;
    bottom: -20px;
    width: calc(100% + 100%);
    height: 4px;
    background-color: #2d91d4;
}

.title strong {
    font-weight: bold;
    /* Añade un pequeño espacio entre 'Nuestros' y 'servicios' */
}
.certifications-carousel {
   margin-top:20px;
}

.modal-content img {
    max-width: 100%;
    max-height: 80vh; /* Altura máxima adaptada a la pantalla */
    object-fit: contain; /* Asegura que la imagen no se deforme */
    border-radius: 10px;
}


.overlay {
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal {
    background: white;
    border-radius: 10px;
    padding: 5px;
    position: relative;
    max-width: 80%;
    max-height: 90%;
    overflow: auto;
  }
  
  .modal-content img {
    display: block;
    margin: auto;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
/* Ajustes responsivos con Media Queries */
@media (max-width: 768px) {

    .text-container span {
        color: white;
        font-family: 'Montserrat';
        font-size: 1.2rem;
        font-weight: bold;
    }

    .services-section {
        padding: unset;
        gap: unset;
    }

    .title {
        font-size: 40px;
        /* Tamaño más pequeño para pantallas intermedias */
    }

    /* Considera permitir que el título se ajuste si es necesario */
    .title {
        white-space: normal;
    }

    .text-container {
        padding: 1.5rem;
        width: unset;
    }

    .text-container>span {
        font-size: 1.2rem;
    }
}

@media (max-width: 480px) {
    .title-container {
        align-items: center;
        padding-left: 0;
    }

    .title {
        font-size: 2em;
        /* Tamaño más pequeño para pantallas móviles */
    }

    .title::after {
        bottom: -10px;
        /* Ajuste si la línea es demasiado baja */
        height: 2px;
        /* Línea más delgada para pantallas pequeñas */
    }
}


.services-grid {
    display: grid;
    justify-content: space-around;
    margin-top: 5em;
    justify-items: center;
    align-items: center;
    padding: 0 8rem;
    grid-template-columns: repeat(3, 1fr);
}

.service {
    background-color: #242e3a;
    color: white;
    width: 80%;
    height: 80%;
    min-width: 40%;
    /* Ajusta este valor según el tamaño deseado de cada recuadro */
    margin-bottom: 20px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    /* Sombra sutil para los recuadros */
    text-align: center;
    border-radius: 10px;
    background-image: linear-gradient(to right, #2e4b57, #0a0e1e);
}

.service img {
    max-width: 100px;
    /* Ajusta este valor según el tamaño deseado de los logos */
    margin-bottom: 10px;
}

h3 {
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    font-size: 30px;
}

.italic {
    font-size: 20px;
    font-family: 'Montserrat Light';
}

.info-section {
    display: flex;
    align-items: center;
    /* Align items vertically */
    justify-content: flex-end;
    padding: 20px;
    padding-right: 10%;
    background-image: linear-gradient(to right, #2e4b57, #0a0e1e);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px 0;
    position: relative;
    /* Needed for absolute positioning of children */
    overflow: visible;
    /* Allows children to be visible outside the box */
    width: 100%
}

.info-section-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
}

.circle-badge {
    position: absolute;
    left: -50px;
    /* Adjust as needed */
    top: 50%;
    transform: translateY(-50%);
    /* Center vertically */
}

.circle-image {
    position: absolute;
    left: 4%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
    width: 5px;
}

.circle-image img {
    width: 300px;
}

.info-item {
    text-align: center;
    margin-left: 20px;
    margin-right: 50px;
}

.info-number {
    font-size: 75px;
    color: white;
    font-family: 'Montserrat';
    font-weight: bold;
}

.info-text {
    margin-top: -10px;
    font-size: 25px;
    color: white;
    font-family: 'Montserrat';
}

@media (max-width: 768px) {

    .circle-image img {
        z-index: 2;
        width: 105px;
    }

    .info-item {
        margin-right: 4%;
        margin-left: 0px;
    }

    .info-number {
        font-size: 15px;
        /* Tamaño más pequeño para pantallas intermedias */
    }

    .info-text {
        font-size: 10px;
        /* Tamaño más pequeño para pantallas intermedias */
        margin-top: 0px;
    }
}

@media (max-width: 480px) {
    .services-section {
        min-height: 100vh;
        height: 100%;
    }

    .info-section {
        padding: 2% 0%
    }

    .info-number {
        font-size: 2em;
        /* Tamaño aún más pequeño para pantallas móviles */
    }

    .info-text {
        font-size: 0.7em;
        /* Tamaño aún más pequeño para pantallas móviles */
    }

    .services-grid {
        padding: 5%;
        flex-wrap: nowrap;
        margin-left: 0;
        margin-right: 0;
        flex-direction: column;
        margin-top: 2em;
        display: flex;
    }

    .service {
        width: 100%;
        padding: 0;
        background: #1c2d3b;
    }

    .service img {
        margin-top: 10px;
    }

    h3 {
        font-size: 1.3em;
    }

    .circle-image {
        display: none;
    }

    .info-item {
        flex: 1;
        margin-left: 0;
        margin-right: 0;
    }
}