.plans-container {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
    padding-bottom: 10rem;
}

.plans-section {
    display: flex;
    flex-direction: column;
    padding: 0;
    background-color: #000b17;
    gap: 4em;
    margin-top: 10em;
    padding: 0 60px;
}

@media (max-width: 768px) {
    .plans-container {
        flex-direction: column;
    }

    .plans-section {
        min-height: 100vh;
        height: 100%;
        padding: 0px;
    }
    
   
}

@media (max-width: 480px) {

}