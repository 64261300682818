.intro-section {
    gap: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    text-align: center;
    padding: 0;
    background-image: url('../../assets/images/intro.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    padding: 3rem 0;
}

.intro-title-container {
    padding-top: 2rem
}

.container-intro-div {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
}

.intro-section h1 {
    font-size: 100px;
    text-transform: uppercase;
    font-family: 'Montserrat Black';
    color: white;
    margin-bottom: 40px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
}

.intro-section h1,
.intro-section h2,
.subtitulo-destacado {
    color: white;
    margin: 0;
    padding: 1rem
}

.intro-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    margin: 0 5em;
}

.subtitulo-destacado {
    font-size: 28px;
    font-weight: bold;
}

.subtitle-container h2 {
    font-size: 28px;
    text-align: center;
}

.subtitulo {
    font-family: 'Montserrat Light';
    font-weight: 200;
}

.subtitle-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.neon-button {
    margin-top: 15px;
    z-index: 1;
    padding: 10px 30px;
    font-family: 'Montserrat';
    font-size: 20px;
    font-weight: 700;
    line-height: 1.5em;
    color: white;
    border: none;
    outline: none;
    border-radius: 15px;
    background: #2d91d4;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
    position: relative;
    overflow: hidden;
}

.neon-button::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.5);
    width: 100%;
    height: 100%;
    background: rgba(45, 145, 212, 0.2);
    border-radius: 30px;
    z-index: -1;
    filter: blur(20px);
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
}

.neon-button:hover::after {
    opacity: 1;
}

.neon-button:hover {
    background: rgba(45, 145, 212, 0.8);
}

.neon-button:focus,
.neon-button:active {
    outline: none;
}

/* Media Queries para dispositivos de tamaño medio */
@media (max-width: 768px) {
    .intro-section {
        min-height: 70vh;
    }
    
    .intro-title-container {
        padding-top: 3rem;
    }
    .intro-section h1::after {
        background: transparent
    }

    .intro-section {
        align-items: center;
        text-align: center
    }

    .intro-section h1 {
        font-size: 90px;
        margin: 10%;
    }

    .intro-container {
        text-align: center;
        margin: 0;
    }

    .subtitulo-destacado,
    .subtitle-container h2 {
        font-size: 60px;
    }

    .neon-button {
        margin-left: 0;
        padding: 10px 30px;
        font-size: 2.4em;
    }

    .subtitle-container {
        margin-left: 0%;
        margin-bottom: unset;
    }
}

.video-thumbnail {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75%;
}

.video-thumbnail img {
    width: 50%;
    height: auto;
}

.play-button {
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 64px;
    height: 64px;
    padding: 0;
}

.play-button img {
    width: 100%;
    height: auto;
}

/* Media Queries para dispositivos móviles */
@media (max-width: 480px) {
    .intro-title-container {
        white-space: nowrap;
        overflow: hidden;
        display: flex;
        justify-content: center;
    }

    .intro-container {
        margin-left: 0;
    }

    .intro-section h1 {
        font-size: 7vw;
    }

    .subtitulo {
        display: none;
    }

    .subtitulo-destacado,
    .subtitle-container h2 {
        font-size: 1rem;
    }

    .neon-button {
        font-size: 5vw;
    }

    .video-thumbnail img {
        width: 100%;
    }

    .video-thumbnail {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
    }

    .play-button {
        width: 48px;
        height: 48px;
    }

    .play-button img {
        width: 100%;
        height: auto;
    }
}