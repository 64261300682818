.testimonials {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #000b17;
    min-height: 100%;
}

.testimonial-container {
    display: flex;
    flex-direction: column;
    padding-top: 10rem;
    padding-bottom: 10rem;
}

.testimonial {
    width: 45%;
    padding: 20px;
    background-color: #f5f5f5;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-bottom: 40px;

}

.testimonial h3 {
    text-align: center;
    margin-bottom: 15px;
}

.testimonial p {
    font-size: 2rem;
    text-align: center;
}

.starts {
        color: white !important;
        display: flex;
        min-width: 10rem;
}

.carrousel {
    width: 100%;

    display: flex;
}

.element {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 16px;
    width: 90%;
    min-height: 100%;
    background: #1c2d3b;
    padding: 20px;
    min-height: 450px;
    justify-content: center
}

.element p {
    font-size: 1.2rem;
    text-align: center;
    color: white;
    padding: 0 20px;
}


.star {
    color: white !important;
    display: flex;
    min-width: 10rem;
}

.carrousel ul {
    gap: 100px;
}

.element-header {
    display: flex,

}

@media (max-width: 768px) {
    .testimonial-container {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }
    
    .title-container {
        margin-bottom: unset;
        padding: 1rem;
    }
    .carrousel ul {
        gap: unset;
    }
    .title::after {
        display: flex;
        position: unset;
        width: unset;
    }
    .star {
        color: white !important;
        display: flex;
        min-width: 10rem;
    }
}
